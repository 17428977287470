import Breadcrumb from 'components/Breadcrumb'

const TitleAdustInventory = () => {
  const bItem: any = [
    { name: 'Dashboard', to: '/' },
    { name: 'Inventory', to: '/inventory/adjust' },
    { name: 'List Inventory', to: '/inventory/list-inventory' }
  ]

  return <Breadcrumb title="List Inventory" items={bItem} />
}

export default TitleAdustInventory
