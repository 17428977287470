import Title from 'pages/skid/inventory/list-inventory/sections/title'
import Tab from 'pages/skid/inventory/list-inventory/sections/tab'
import ActionTable from 'pages/skid/inventory/list-inventory/sections/action-table'

const Store = () => {
  return (
    <>
      <Title />
      <ActionTable />
      <Tab />
    </>
  )
}

export default Store
