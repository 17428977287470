import Breadcrumb from 'components/Breadcrumb'

const TitleSkidList = () => {
  const bItem: any = [
    { name: 'Dashboard', to: '/' },
    { name: 'SKID', to: '/skid/list' },
    { name: 'List', to: '/skid/list' }
  ]

  return <Breadcrumb title="SKID List" items={bItem} />
}

export default TitleSkidList
