import { getDetail } from 'stores/inventory/adjust/actions/adjust'
import xhrInstance from 'services/config'
import objToParams from 'utils/objToParams'
import { get } from 'lodash'

const getAdjustment = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/list-adjustment`, params))
}

const getInventory = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/list`, params))
}

const getInventoryV2 = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/v2/list`, params))
}

const previewInventoryV2 = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/v2/preview`, params))
}

const downloadInventoryV2 = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/v2/download`, params), {
    responseType: 'blob'
  })
}

const previewInventory = (params: Record<string, any>) => {
  return xhrInstance.get(objToParams('/inventory/preview', params))
}

const getAdjustmentDetail = (id: number | string) => {
  return xhrInstance.get(`/inventory/detail/${id}`)
}

const getAdjustmentHistory = (id: number | string) => {
  return xhrInstance.get(`/inventory/history/${id}`)
}

const addRemarkAdjustment = (params: any) => {
  return xhrInstance.put(`/inventory/submit-adjustment`, params)
}

const previewInventoryAdjustment = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/preview-adjustment`, params))
}

const getListAdjsutmentV2 = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/v2/list-adjustment`, params))
}

const getDetailAdjustmentV2 = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/v2/detail-adjustment`, params))
}

const PrintService = {
  getAdjustment,
  getInventory,
  getAdjustmentDetail,
  getAdjustmentHistory,
  addRemarkAdjustment,
  previewInventory,
  previewInventoryAdjustment,
  getListAdjsutmentV2,
  getDetailAdjustmentV2,
  getInventoryV2,
  downloadInventoryV2,
  previewInventoryV2
}

export default PrintService
