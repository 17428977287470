import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from 'stores'
import { Modal, Button, Input, Pagination, Form, Table, Row } from 'antd'
import {
  setPageSize,
  toggleActionDialog,
  resetAdjustInv
} from 'stores/inventory/adjust'
import {
  getDetailAdjustmentV2,
  getListAdjustmentV2
} from 'stores/inventory/adjust/actions/adjust'
import { addRemark } from 'stores/inventory/adjust/actions/adjust'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { EyeOutlined } from '@ant-design/icons'
import zeroSize from 'utils/zeroSize'
import { set } from 'lodash'

dayjs.extend(utc)

interface TableProps {
  dataTable: any
  columns: any
  isLoadingData: any
  currentPage: any
  totalData: any
  pageSize: any
  handlePaginate: any
  handleRemarks: any
}

const TablePagination = ({
  dataTable,
  columns,
  isLoadingData,
  currentPage,
  totalData,
  pageSize,
  handlePaginate,
  handleRemarks
}: TableProps) => {
  return (
    <>
      {/* <ActionDialog dataRecord={currentRecord} /> */}
      <Table
        bordered
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        scroll={{ x: 'calc(700px + 50%)' }}
      />
      <Row className="mt-5" justify="end">
        {/* <Pagination
          current={currentPage || 1}
          total={totalData}
          pageSize={pageSize || 10}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        /> */}
      </Row>
    </>
  )
}

const ModalRemark = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const [form] = Form.useForm()

  const [dataTable, setDataTable]: any = useState([])
  const [remarks, setRemarks] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(true)
  // const [newDatas, setNewDatas] = useState([])
  const {
    dialogAction,
    isLoading,
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    // detailData,
    thick,
    width,
    length1,
    length2,
    date
  } = useSelector((state: any) => state.adjustInv)

  const handleInitData = async () => {
    // await dispatch(
    //   get({
    //     page: 1,
    //     per_page: 10
    //   })
    // ).unwrap()
    // await dispatch(
    //   getDetailAdjustmentV2({
    //     date: date
    //   })
    // ).unwrap()
    const finalDate = dayjs.utc(dataRecord?.date).format('YYYY-MM-DD')

    const data = await dispatch(
      getDetailAdjustmentV2({ date: finalDate })
    ).unwrap()

    // const parseData = data.data?.map((item: any) => ({ ...item, remarks: '' }))
    const parseData = data.data?.map((item: any) => ({ ...item }))

    setDataTable(parseData)
  }

  useEffect(() => {
    dialogAction && handleInitData()
    // return () => {
    //   dispatch(resetAdjustInv())
    // }
  }, [dialogAction])

  const newDatas = dataTable?.map((item: any, index: number) => {
    const currentPg = currentPage || 1
    const pgSize = pageSize || 10
    return {
      ...item,
      // remarks: '',
      no: pgSize * (currentPg - 1) + index + 1
    }
  })

  const handleActionUp = (record: any) => {
    // setCurrentRecord(record)
    dispatch(toggleActionDialog(true))
  }

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize))
    const params: any = {
      page: page,
      per_page: pageSize,
      date
    }
    if (search) {
      params.keyword = search
    }
    // await dispatch(getDetailAdjustmentV2(params)).unwrap()
  }

  const handleRemarks = (event: any, index: number) => {
    const value = event.target.value
    setRemarks(value)
    const changeData = dataTable.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, remarks: value }
      }
      return item
    })
    setDataTable(changeData)
    validateRemarks(changeData)
  }

  const validateRemarks = (updatedData: any[]) => {
    const allRemarksFilled = updatedData.every((item) => item.remarks !== null)
    setDisableSubmit(!allRemarksFilled)
  }

  const columns: any = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      align: 'center'
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      width: 120
    },
    {
      title: 'Code Skid',
      dataIndex: 'code_skid',
      key: 'code_skid',
      width: 150
    },
    {
      title: 'Size SKID',
      children: [
        {
          title: 'T',
          key: 'skidT',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.thick)
        },
        {
          title: 'W',
          key: 'skidW',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.width)
        },
        {
          title: 'L1',
          key: 'skidL1',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.length1)
        },
        {
          title: 'L2',
          key: 'skidL2',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.length2)
        }
      ]
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 50,
      align: 'center',
      render: (status: string) => (status ? status.toUpperCase() : '-')
    },
    {
      title: 'Last Scan',
      key: 'last',
      width: 100,
      render: (text: any) =>
        text.last_scan
          ? text.last_scan === '-'
            ? '-'
            : dayjs.utc(text.last_scan).format('DD/MM/YYYY HH:mm')
          : 'No'
    },
    {
      title: 'Scanned',
      key: 'scanned',
      dataIndex: 'scanned',
      width: 50,
      align: 'center',
      render: (scanned: string) => (scanned ? scanned.toUpperCase() : '-')
    },
    {
      title: 'Remarks',
      key: 'remarks',
      dataIndex: 'remarks',
      width: 200,
      align: 'center',
      render: (text: string, record: any, index: any) => (
        <Input
          onChange={(event) => handleRemarks(event, index)}
          placeholder={'add remarks'}
          value={dataTable[index]?.remarks || ''}
        />
      )
    }
  ]

  const handleCancel = async () => {
    // Fetch the new data after submission
    const newData = await dispatch(
      getDetailAdjustmentV2({
        date: dayjs.utc(dataRecord?.date).format('YYYY-MM-DD')
      })
    ).unwrap()

    const parseData = newData.data?.map((item: any) => ({
      ...item,
      remarks: ''
    }))
    // Parse and set the new table data
    setDataTable(parseData) // Set the updated data in the table
    form.resetFields()
    dispatch(toggleActionDialog(false))
  }

  // const disableSubmit =
  //   dataTable.filter((item: any) => item.remarks !== '').length > 0
  //     ? false
  //     : true

  // const handleSubmit = async () => {
  //   const req = {
  //     data_assets: dataTable
  //       .filter((item: any) => item.remarks !== '')
  //       ?.map((r: any) => ({
  //         asset_id: r.asset_id,
  //         id: r.id,

  //         // remarks: should be input value from handleRemarks function ?
  //         remarks
  //       }))
  //   }

  //   await dispatch(
  //     addRemark({
  //       body: req
  //     })
  //   ).unwrap()
  //   setRemarks('')
  //   await dispatch(getDetailAdjustmentV2({ page: 1, limit: 10 })).unwrap()
  //   // handleCancel()
  // }

  const handleSubmit = async () => {
    // Prepare the request payload
    const req = {
      data_assets: dataTable
        .filter((item: any) => item.remarks !== '')
        ?.map((r: any) => ({
          asset_id: r.asset_id,
          id: r.id,
          remarks: r.remarks // Use the remarks from dataTable
        }))
    }

    try {
      // Dispatch the addRemark action and wait for it to complete
      await dispatch(
        addRemark({
          body: req
        })
      ).unwrap()

      // Clear the remarks state and table data immediately after submit
      setRemarks('')

      // Fetch the new data after submission
      const newData = await dispatch(
        getDetailAdjustmentV2({
          page: 1,
          limit: 10,
          date: dayjs.utc(dataRecord?.date).format('YYYY-MM-DD')
        })
      ).unwrap()

      const parseData = newData.data?.map((item: any) => ({
        ...item
        // remarks: ''
      }))
      // Parse and set the new table data
      setDataTable(parseData) // Set the updated data in the table
      setDisableSubmit(true)

      await dispatch(getListAdjustmentV2({ page: 1, per_page: 10 })).unwrap()
    } catch (error) {
      console.error('Error submitting remarks:', error)
    }
  }

  return (
    <Modal
      width={'80%'}
      forceRender
      open={dialogAction}
      title="Adjustment Table source"
      onCancel={() => handleCancel()}
      footer={[
        <Button onClick={() => handleCancel()} key="back">
          Cancel
        </Button>,
        <Button
          type="primary"
          disabled={disableSubmit}
          onClick={() => handleSubmit()}
          key="submit"
        >
          Submit
        </Button>
      ]}
    >
      <div className="mt-5">
        <TablePagination
          dataTable={newDatas}
          columns={columns}
          isLoadingData={isLoadingData}
          currentPage={currentPage}
          totalData={totalData}
          pageSize={pageSize}
          handlePaginate={handlePaginate}
          handleRemarks={handleRemarks}
        />
      </div>
    </Modal>
  )
}

export default ModalRemark
