import Title from 'pages/skid/inventory/adjustment/sections/title'
import Tab from 'pages/skid/inventory/adjustment/sections/tab'
import ActionTable from 'pages/skid/inventory/adjustment/sections/action-table'

const Store = () => {
  return (
    <>
      <Title />
      {/* <ActionTable /> */}
      <Tab />
    </>
  )
}

export default Store
