import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from 'stores'
import debounce from 'lodash/debounce'
import * as inAction from 'stores/inventory/adjust/actions/adjust'
import {
  setSearch,
  setThick,
  setWidth,
  setLength1,
  setLenght2,
  setDate,
  resetAdjustInv
} from 'stores/inventory/adjust'
import dayjs from 'dayjs'

const useAction: any = () => {
  const debounceTime = 500
  const dispatch = useDispatch<AppDispatch>()
  const { search, thick, width, length1, length2, pageSize, date } =
    useSelector((state: any) => state.adjustInv)

  const networkRequest = useCallback((params: any) => {
    dispatch(inAction.getInventoryV2(params)).unwrap()
  }, [])

  const handleSearch = () => {
    const params: any = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick,
      width,
      length1,
      length2
    }
    if (date) {
      params.start_date = dayjs(date[0]).format('YYYY-MM-DD')
      params.end_date = dayjs(date[1]).format('YYYY-MM-DD')
    }

    networkRequest(params)
  }

  const debounceSearch = useMemo(() => {
    return debounce(networkRequest, debounceTime)
  }, [networkRequest])

  const handleChangeSearch = (event: any) => {
    const inputValue = event.target.value
    const params: any = {
      page: 1,
      per_page: pageSize,

      code_skid: inputValue,
      thick,
      width,
      length1,
      length2
    }
    if (date) {
      params.start_date = dayjs(date[0]).format('YYYY-MM-DD')
      params.end_date = dayjs(date[1]).format('YYYY-MM-DD')
    }
    dispatch(setSearch(inputValue))
    debounceSearch(params)
  }

  const debounceThick = useMemo(() => {
    return debounce(networkRequest, debounceTime)
  }, [networkRequest])

  const handleChangeDate = (value: any) => {
    dispatch(setDate(value))
    const params: any = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick,
      width,
      length1,
      length2
    }
    if (value) {
      params.start_date = dayjs(value[0]).format('YYYY-MM-DD')
      params.end_date = dayjs(value[1]).format('YYYY-MM-DD')
    }
    networkRequest(params)
  }

  const handleChangeThick = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick: value,
      width,
      length1,
      length2
    }
    dispatch(setThick(value))
    debounceThick(params)
  }

  const debounceWidth = useMemo(() => {
    return debounce(networkRequest, debounceTime)
  }, [networkRequest])

  const handleChangeWidth = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick,
      width: value,
      length1,
      length2
    }
    dispatch(setWidth(value))
    debounceWidth(params)
  }

  const debounceLength1 = useMemo(() => {
    return debounce(networkRequest, debounceTime)
  }, [networkRequest])

  const handleChangeLenghth1 = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick,
      width,
      length1: value,
      length2
    }
    dispatch(setLength1(value))
    debounceLength1(params)
  }

  const debounceLength2 = useMemo(() => {
    return debounce(networkRequest, debounceTime)
  }, [networkRequest])

  const handleChangeLenghth2 = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,

      code_skid: search,
      thick,
      width,
      length1,
      length2: value
    }
    dispatch(setLenght2(value))
    debounceLength2(params)
  }

  const handleClear = (form: any) => {
    form.resetFields()
    dispatch(resetAdjustInv())
    networkRequest({
      page: 1,
      per_page: 10
    })
  }

  return {
    networkRequest,
    handleSearch,
    handleChangeSearch,
    handleChangeThick,
    handleChangeWidth,
    handleChangeLenghth1,
    handleChangeLenghth2,
    handleChangeDate,
    handleClear
  }
}

export default useAction
