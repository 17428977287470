import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from 'stores'
import { Row, Button, Pagination, Table } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import {
  get,
  getDetailAdjustmentV2,
  getListAdjustmentV2
} from 'stores/inventory/adjust/actions/adjust'
import ActionDialog from 'pages/skid/inventory/adjustment/sections/table-pagination-adjust/dialog/table_adjust'
import {
  setPageSize,
  toggleActionDialog,
  resetAdjustInv
} from 'stores/inventory/adjust'
import zeroSize from 'utils/zeroSize'
import dayjs from 'dayjs'

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [currentRecord, setCurrentRecord] = useState<any>({})
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    thick,
    width,
    length1,
    length2,
    dialogAction
  } = useSelector((state: any) => state.adjustInv)

  const handleInitData = async () => {
    // await dispatch(
    //   get({
    //     page: 1,
    //     per_page: 10
    //   })
    // ).unwrap()
    await dispatch(
      getListAdjustmentV2({
        page: 1,
        per_page: 10
      })
    ).unwrap()
  }

  useEffect(() => {
    handleInitData()
    return () => {
      dispatch(resetAdjustInv())
    }
  }, [])

  const newDatas = datas?.map((item: any, index: number) => {
    const currentPg = currentPage || 1
    const pgSize = pageSize || 10
    return {
      ...item,
      no: pgSize * (currentPg - 1) + index + 1
    }
  })

  const handleActionUp = async (record: any) => {
    const d = dayjs.utc(record.date).format('YYYY-MM-DD')
    setCurrentRecord(record)
    record && (await dispatch(getDetailAdjustmentV2({ date: d })).unwrap())
    dispatch(toggleActionDialog(true))
  }

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize))
    const params: any = {
      page: page,
      per_page: pageSize,
      thick,
      width,
      length1,
      length2
    }

    if (search) {
      params.keyword = search
    }

    await dispatch(getListAdjustmentV2(params)).unwrap()
  }

  const columnsList: any = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      align: 'center'
    },
    {
      title: 'Folder',
      dataIndex: 'date',
      key: 'date',
      width: 150,

      render: (text: any) =>
        text
          ? text === '-'
            ? '-'
            : dayjs.utc(text).format('DD/MM/YYYY')
          : 'No'
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (dataRecord: any) => {
        return (
          <Button
            onClick={() => handleActionUp(dataRecord)}
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
          />
        )
      }
    }
  ]
  const columns: any = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      align: 'center'
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      width: 120
    },
    {
      title: 'Code Skid',
      dataIndex: 'code_skid',
      key: 'code_skid',
      width: 150
    },
    {
      title: 'Size SKID',
      children: [
        {
          title: 'T',
          key: 'skidT',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.thick)
        },
        {
          title: 'W',
          key: 'skidW',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.width)
        },
        {
          title: 'L1',
          key: 'skidL1',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.length1)
        },
        {
          title: 'L2',
          key: 'skidL2',
          width: 80,
          align: 'center',
          render: (dataRecord: any) => zeroSize(dataRecord?.length2)
        }
      ]
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 50,
      align: 'center',
      render: (status: string) => (status ? status.toUpperCase() : '-')
    },
    {
      title: 'Last Scan',
      key: 'last',
      // dataIndex: "scanned",
      width: 100,

      render: (text: any) =>
        text.last_scan
          ? text.last_scan === '-'
            ? '-'
            : dayjs.utc(text.last_scan).format('DD/MM/YYYY HH:mm')
          : 'No'
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (dataRecord: any) => {
        return (
          <Button
            onClick={() => handleActionUp(dataRecord)}
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
          />
        )
      }
    }
  ]

  return (
    <>
      <ActionDialog dataRecord={currentRecord} />
      <Table
        bordered
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columnsList}
        dataSource={newDatas}
        pagination={false}
        // rowClassName={""}
        scroll={{ x: 'calc(700px + 50%)' }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </>
  )
}

export default TablePagination
